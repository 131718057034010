import React, { useEffect } from 'react';

export const Confirmlogin: React.FC = () => {
  useEffect(() => {
    const cook = process.env.REACT_APP_COOKIE_NAME;
    const urlParams = new URL(window.location.href).searchParams;
    console.log('urlParams===>', urlParams);

    const referrer = urlParams.get('referrer')?.toString() || '';
    const token = urlParams.get('token'); // Отримуємо значення "токен" з гет параметра
    let tokenReturn = token || 'none';
    const utm_medium = urlParams.get('utm_medium') || '';
    const utm_campaign = urlParams.get('utm_campaign') || '';

    if (token) {
      const expiresDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 180).toUTCString();
      document.cookie = `${cook}=${token}; domain=.expertus.com.ua; path=/; expires=${expiresDate}; secure=true; sameSite=none`;
    }
    console.log('referrer===>', referrer);
    if (referrer) {
      const cookies = document.cookie.split('; ').reduce((result, c) => {
        const [key, val] = c.split('=').map(decodeURIComponent);
        //@ts-ignore
        result[key] = val;
        return result;
      }, {});
      //@ts-ignore
      tokenReturn = cookies[cook] || 'none';

      const url = new URL(referrer);

      if (tokenReturn) {
        if (url.searchParams.has('tokenCoock')) {
          url.searchParams.set('tokenCoock', tokenReturn);
        } else {
          url.searchParams.append('tokenCoock', tokenReturn);
        }
      }
      if (utm_medium) {
        if (url.searchParams.has('utm_medium')) {
          url.searchParams.set('utm_medium', utm_medium);
        } else {
          url.searchParams.append('utm_medium', utm_medium);
        }
      }
      if (utm_campaign) {
        if (url.searchParams.has('utm_campaign')) {
          url.searchParams.set('utm_campaign', utm_campaign);
        } else {
          url.searchParams.append('utm_campaign', utm_campaign);
        }
      }

      window.location.replace(url.toString());
    }
  }, []);

  // Return null to indicate that there is no HTML to render
  return null;
};

